import React from 'react';
import {
  Container, Row, Col
} from 'react-bootstrap';
import styled from 'styled-components';

import Title from 'components/common/Title';
import ExternalLink from 'components/common/ExternalLink';

import Subtitle from 'components/common/Subtitle';
import {useApp} from 'components/providers/AppProvider';
import Button from 'components/common/Button';

import colors from 'utility/colors';
import PrimaryLogo from 'assets/logos/htsLogoOutline.png';

const StyledContainer = styled(Container)`
  position: relative;
  min-height: ${props => props.height};
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.image});
  background-color: ${props => props.background || 'white'};
  z-index: 98;
`;

const HeroLogo = ({
  withLogo,
  logo,
  backLink,
  logoWidth,
  logoHeight,
  className
}) => {
  if(!withLogo && !logo) return null;

  if(backLink) {
    return <a href={backLink}>
      <img alt={'Hero Logo'}
        src={logo ? logo : PrimaryLogo} 
        style={{marginRight: 25, width: logoWidth, height: logoHeight}} 
        className={className}
        /> 
    </a>
  }else{
    return <img alt={'Hero Logo'}
      src={logo ? logo : PrimaryLogo} 
      style={{marginRight: 25, width: logoWidth, height: logoHeight}} 
      className={className}
      /> 
  }
}

const Hero = ({
  title, 
  subtitle,
  subtitleSize=26,
  subtitleStyle={},
  xs=12, 
  xsOffset=0,
  md=10,
  mdOffset=1, 
  lgOffset=1,
  lg=10,
  withLogo=false, 
  logo,
  backLink,
  backLinkCopy='Go Back',
  logoWidth=80,
  logoHeight=98,
  body, 
  cta,
  firstColStyle={}, 
  titleStyle={},
  titleSize=54,
  onClickCta,
  backgroundImage,
  backgroundColor,
  overlayColor='black',
  height='85vh',
  maxWidth=1200,
  hideOverlay=false,
  opacity= .5,
  children
}) => {
  const {breakpoints} = useApp();

  return <div style={{position: 'relative'}}>
    <StyledContainer 
      fluid 
      height={height}
      image={backgroundImage}
      background={backgroundColor}
      style={{
        paddingTop: breakpoints.md ? '15vh' : '5vh',
        paddingBottom: breakpoints.md ? '10vh' : '0vh'
      }}
      >
      {hideOverlay ? null : <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: overlayColor, opacity: opacity}}/>}
      <Container style={{maxWidth: maxWidth, paddingBottom: 30}}>
        <Row>
          <Col xs={{span: xs, offset: xsOffset, order: 1}} md={{span: md, offset: mdOffset, order: 12}} lg={{span: lg, offset: lgOffset, order: 12}} style={firstColStyle}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <HeroLogo 
                withLogo={withLogo} 
                logo={logo} 
                logoHeight={logoHeight} 
                logoWidth={logoWidth} 
                backLink={backLink} 
                className={"d-none d-sm-block"}/>
              <div>
                {!breakpoints.lg && backLink ? 
                  <ExternalLink href={backLink}>
                    {backLinkCopy}
                  </ExternalLink>
                : null}
                {title ? <Title size={titleSize} style={{fontWeight: 500, color: colors.WHITE, ...titleStyle}}>
                  {title}
                </Title> : null}
                
                {subtitle ? <Subtitle size={subtitleSize} style={{fontWeight: 400, color: colors.WHITE, ...subtitleStyle}}>
                  {subtitle}
                </Subtitle> : null}
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {onClickCta && <Button id={'hero-cta'} onClick={onClickCta} background={colors.ACCENT} style={{width: 'auto', textAlign: 'center', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, height: 'auto'}}>
                    {cta}
                  </Button>}
                </div>
              </div>
            </div>
          </Col>
          {body ? body : null}
        </Row>
      </Container>
    </StyledContainer>
    <div style={{position: 'relative', zIndex: 99}}>
      {children}
    </div>
  </div>
}

export default Hero